import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

import GridGallery from '../components/GridGallery/GridGallery.js'

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex', maxWidth: '90vw' }}>
          <div style={{ color: 'black', padding: '150px 0 0', margin: '0 auto' }} >
            <p>.</p>
            <GridGallery />
          </div>
        </div>
        <div className={classes.container} style={{ display: 'flex', maxWidth: 'unset' }}>
          <div style={{ color: 'black', padding: '50px 0 0', margin: '0 auto' }} >
            <p style={{fontSize: '1.1em'}}>Aby zobaczyć relację w gościu niedzielnym z dnia jedności kliknij w 
              <a href="https://opole.gosc.pl/doc/5870584.200-osob-wzielo-udzial-w-Dniu-Jednosci"> link</a>
            </p>
          </div>
        </div>
        <div className={classes.container} style={{ display: 'flex', maxWidth: 'unset' }}>
          <img src={'/dzien-jednosci-2019.jpg'} alt={'dzien-jednosci-2019'} style={{ padding: '30px', margin: '5vw auto 0', maxWidth: '100%' }} />
        </div>
      </div>
    </Layout>
  );
}
