import React from 'react';
import Gallery from 'react-grid-gallery';

const images = [
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6693-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6693_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6696-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6696_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6700-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6700_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6707-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6707_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6711-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6711_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6714-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6714_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6719-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6719_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6721-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6721_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6727-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6727_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6730-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6730_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6734-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6734_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6739-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6739_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6742-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6742_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6746-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6746_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6750-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6750_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
},
{
    src: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/min/DSC_6752-min.jpg",
    thumbnail: "/2019-09-21_Wspólnoty_opolskiej_Odnowy/thumbnails/DSC_6752_tn.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212    
}
]



const GridGallery = () => (
    <Gallery images={images}/>
);

export default GridGallery;